export const hostname = window && window.location && window.location.hostname;
let backendUrl = 'https://apilmsdev.azurewebsites.net/';
let frontendUrl = 'localhost:3000';

switch(hostname) {
    case 'localhost':
        backendUrl = 'https://apilmsdev.azurewebsites.net/';

        break;

    default:
        frontendUrl = 'lms.reconart.com';
}


export const FRONTEND_URL = frontendUrl;
export const BACKEND_URL = backendUrl;
export const ENDPOINTS = {
    login: {
        post: {
            url: 'connect/token',
            type: 'POST',
            params: {
                grant_type: "password",
                scope: "admin_access offline_access",
                client_id: "admin.js.client"
            },
            passErrors: true,
            usesUrlEncodedParams: true
        }
    },

    REFRESH: {
        url: 'connect/token',
        params: {
            grant_type: "refresh_token",
            scope: "admin_access offline_access",
            client_id: "admin.js.client"
        }
    },

    logout: {
        post: {
            url: 'connect/revocation',
            type: 'POST',
            params: {
                token_type_hint: "refresh_token",
                client_id: "admin.js.client"
            },
            usesUrlEncodedParams: true
        }
    },

    users_activate: {
        post: {
            url: 'users/activate',
            type: 'POST',
            passErrors: true
        }
    },

    forgotten_password: {
        post: {
            url: 'schools/forgotten-password',
            type: 'POST',
            passErrors: true
        }
    },

    change_forgotten_password: {
        post: {
            url: 'schools/change-forgotten-password',
            type: 'POST',
            passErrors: true
        }
    },

    account: {
        GET_ONE: {
            isSecure: true,
            url: 'users/account',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'users/account',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    teacher_account: {
        GET_ONE: {
            isSecure: true,
            url: 'schools/teachers/account/details',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/teachers/account/details',
            type: 'PUT',
            passErrors: true
        }
    },

    change_password: {
        UPDATE: {
            isSecure: true,
            url: 'schools/teachers/change-password',
            type: 'POST',
            passErrors: true
        }
    },

    home_address: {
        GET_LIST: {
            isSecure: true,
            url: 'users/address',
            type: 'GET',
            passErrors: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'users/address',
            type: 'GET',
            passErrors: true
        },
        GET_ONE: {
            isSecure: true,
            url: 'users/address',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'users/address',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },


    schools: {
        GET_LIST: {
            isSecure: true,
            url: 'schools',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'schools',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },


    dashboard: {
        GET_LIST: {
            isSecure: true,
            url: 'dashboard',
            type: 'GET',
        },
    },
    
    payment: {
        get: {
            url: 'registrations/checkout-session',
            type: 'GET',
            needsId: true,
            passErrors: true
        }
    },

    change_account_process: {
        post: {
            url: 'schools/change-email',
            type: 'POST',
            passErrors: true,
            needsId: true
        }
    }

};
