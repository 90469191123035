import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, CircularProgress} from "@material-ui/core";
import {fetchIt, getErrorMessage} from "../helpers";
import {loadStripe} from '@stripe/stripe-js';


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '25%',
        left: 'calc(50% - 54px)',
    },
    errorRoot: {
        position: 'absolute',
        top: '25%',
        left: 'calc(50% - 160px)',
        width: 320
    },
    text: {
        fontSize: 24,
        paddingTop: 20,
        marginLeft: -25,
    },
    errorTitle: {
        fontSize: 18,
        paddingTop: 20,
        color: 'darkgrey',
    },
    errorText: {
        fontSize: 18,
        paddingTop: 20,
        color: 'grey',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: '#1a90ff',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));


const Payment = props => {
    const [error, setError] = useState(false);

    useEffect(() => {
        const getStripe = async (PublishableKey, sessionId) => {
            const stripePromise = await loadStripe(PublishableKey);
            const stripe = await stripePromise;
            const result = await stripe.redirectToCheckout({sessionId: sessionId});

            if (result.error) {
                console.log('Error:', result.error.message);
            }
        }


        fetchIt(
            'get',
            'payment',
            {id: props.match.params.hash},
            {'x-lms-tnt': props.match.params.tenant}
        ).then(response => {
            switch (response.status) {
                case 200:
                    const data = response.data;

                    return getStripe(data.PublishableKey, data.CheckoutSessionId);

                case 400:
                    let errorMessage = 'The link is NOT valid.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    setError(errorMessage);

                    break;

                default:
                    setError('Oops, something went wrong!');
            }
        });
    });


    const classes = useStyles();
    const {staticContext, ...other} = props;


    return (
        <div className={classes.root}>
            {
                !error ?
                    <>
                        <CircularProgress
                            variant="determinate"
                            className={classes.bottom}
                            size={40}
                            thickness={4}
                            {...other}
                            value={100}
                        />
                        <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            className={classes.top}
                            classes={{
                                circle: classes.circle,
                            }}
                            size={40}
                            thickness={4}
                            {...other}
                        />
                        <Typography className={classes.text}>Loading...</Typography>
                    </>
                :
                    <div className={classes.errorRoot}>
                        <Typography variant='h2' className={classes.errorTitle}>ERROR</Typography>
                        <Typography className={classes.errorText}>{error}</Typography>
                    </div>
            }
        </div>
    );
};

export default Payment;
