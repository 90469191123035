import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {withStyles} from '@material-ui/core/styles';
import {notificationService} from './services';
import {green, cyan, blue} from "@material-ui/core/colors";


const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        color: blue[900],
        backgroundColor: cyan[100],
    },
    warning: {
        backgroundColor: theme.palette.warning.dark,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(),
    },
});


const Notification = props => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('Message');
    const [variant, setVariant] = useState('info');
    const [autoHideDuration, setAutoHideDuration] = useState(4000);


    useEffect(() => {
        notificationService.notification.subscribe(notification => {
            if (notification.show) {
                setOpen(true);
                setMessage(notification.message);
                setVariant(notification.type);
                setAutoHideDuration(notification.autoHideDuration);
            } else {
                setOpen(false);
            }
        });
    }, []);


    const Icon = variantIcon[variant];
    const {classes, className} = props;


    const onClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        notificationService.hide();
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            style={{zIndex: 20000000}}
        >
            <SnackbarContent
                className={classNames(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                        {message}
                </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
}

export default withStyles(styles)(Notification);
