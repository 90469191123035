import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Login, ChangeForgottenPassword, ForgottenPassword, TeacherPassword, ChangeEmail} from "./Auth";
import {Payment, CanceledPayment, ThankYou} from './Payments';



const Routes = () => {
    return (
        <Switch>
            <Route exact path="/change-email/:tenantHash/:hash" component={ChangeEmail} />
            <Route exact path="/users/activate/:tenant/:hash" component={TeacherPassword} />
            <Route exact path="/users/activate/:tenant" component={TeacherPassword} />
            <Route exact path="/users/activate" component={TeacherPassword} />
            <Route exact path="/forgotten-password" component={ForgottenPassword} />
            <Route path="/password-reset" component={ChangeForgottenPassword} />
            <Route exact path="/pay/:tenant/:hash" component={Payment} />,
            <Route exact path="/success" component={ThankYou} />,
            <Route exact path="/cancel" component={CanceledPayment} />,
            <Route component={Login}/>,
        </Switch>
    );
}

export default Routes;
