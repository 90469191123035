import React, {useState, useEffect} from "react";
import {fetchIt, getErrorMessage} from '../helpers';
import {Redirect} from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Typography} from "@material-ui/core";
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import {Card} from '@material-ui/core';
import {authenticationService, isLoadingService, notificationService} from "../services";

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        backgroundColor: '#FCFCFE'
    },

    card: {
        minWidth: 360,
        padding: theme.spacing(4),
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 50,
        borderRadius: 10,
        boxShadow: 'none',
        border: '1px solid #e0e0e3',
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));


const ChangeEmail = (props) => {
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState('');
    const hash = props.match.params.hash;
    const tenant = props.match.params.tenantHash;
    const classes = useStyles();

    useEffect(() => {
        if (hash || hash.length > 0 || tenant || tenant.length > 0) {
            isLoadingService.setIsLoading(true);

            fetchIt(
                'post',
                'change_account_process',
                {id: hash},
                {'x-lms-tnt': tenant}
            ).then(response => {
                switch (response.status) {
                    case 200:
                        let message = 'Email is changed.';
                        if (response.data.Message) {
                            message = response.data.Message;
                        }

                        notificationService.notify({message});

                        setRedirect(true);

                        authenticationService.logout();

                        break;

                    case 400:
                        let errorMessage = 'Oops, something went wrong!.';
                        if (typeof response.data.HasValidationErrors !== 'undefined') {
                            errorMessage = getErrorMessage(response.data);
                        }
                        setError(errorMessage);
                        notificationService.notify({
                            message: errorMessage,
                            type: 'error'
                        });
                        break;

                    default:
                        notificationService.notify({
                            message: 'Oops, something went wrong!',
                            type: 'error'
                        });
                }
            }).catch(error => {

            }).then(() => {
                // Dispatch an action letting react-admin know an API call has ended
               isLoadingService.setIsLoading(false);
            });
       }
    }, []);  // eslint-disable-line


    return (
        <>
        {
            !redirect ?
            <div className={classnames(classes.main)}>
                <Card className={classnames(classes.card)}>
                    {error === '' ?
                        <>
                            <div className={classnames(classes.spinnerContainer)}>
                                <CircularProgress style={{color: '#3B9287'}}/>
                            </div>
                            
                            <Typography variant='h5' style={{textAlign: 'center', paddingTop: 25}}>
                                Changing Email/Login...
                            </Typography>
                        </>
                    :
                        <Typography variant='h5' style={{textAlign: 'center', paddingTop: 25}}>
                            {error}
                        </Typography>
                    }
                    
                </Card>          
            </div>
        :
            <Redirect to='/login' />
        }
        </>
        
    );
}

export default ChangeEmail;
