import {BehaviorSubject} from 'rxjs';


export const userDataSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('account')));
export const schoolDataSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('school')));

export const userDataService = {
    userData: userDataSubject.asObservable(),
    get userDataValue () {return userDataSubject.value},
    schoolData: schoolDataSubject.asObservable(),
    get schoolDataValue () {return schoolDataSubject.value}
};
