import React, {useState, useEffect, useRef} from 'react';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {authenticationService, userDataService, isLoadingService} from '../services';
import Cookies from 'universal-cookie';
import {FRONTEND_URL} from "../config/endpoints";
import {
    Card,
    CardActions,
    Button,
    Avatar,
    CircularProgress,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import classnames from 'classnames';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 360,
        padding: theme.spacing(4),
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
    },
}));


const Login = ({backgroundImage}) => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState();
    const [school, setSchool] = useState();
    const containerRef = useRef();

    const cookies = new Cookies();


    if (cookies.get('lms_logging_out')) {
        cookies.remove(
            'lms_logging_out',
            {
                path: '/',
                domain: '.' + FRONTEND_URL,
            }
        );
        authenticationService.logout();
    }

    useEffect(() => {
         if (!!school) {
            cookies.set(
                'lms_user',
                JSON.stringify(authenticationService.currentUserValue),
                {
                    path: '/',
                    domain: '.' + FRONTEND_URL,
                }
            );

            cookies.set(
                'lms_user_account',
                JSON.stringify(userDataService.userDataValue),
                {
                    path: '/',
                    domain: '.' + FRONTEND_URL,
                }
            );

            cookies.set(
                'lms_user_school',
                JSON.stringify(userDataService.schoolDataValue),
                {
                    path: '/',
                    domain: '.' + FRONTEND_URL,
                }
            );

            window.location = (
                userDataService.userDataValue.Role === 'Teacher' ?
                    'https://teachers.' + FRONTEND_URL + '/al' :
                    'https://admin.' + FRONTEND_URL + '/al'
            );
        }
    }, [school]); // eslint-disable-line


    useEffect(() => {
        isLoadingService.isLoadingObject.subscribe(data => setLoading(data));
        userDataService.schoolData.subscribe(data => setSchool(data));
    }, []);


    useEffect(() => {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
    });


    let backgroundImageLoaded = false;
    const updateBackgroundImage = () => {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = `url(https://source.unsplash.com/1600x900?dance)`;
            backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
        if (backgroundImage) {
            const img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };


    const classes = useStyles();


    const onSubmit = e => {
        e.preventDefault();

        authenticationService.login(username, password);
    }

    return (
        <div>
            {
                !userDataService.userDataValue ?
                    <div
                        className={classnames(classes.main)}
                        ref={containerRef}
                    >
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar>
                            </div>
                            <form
                                className={classes.form}
                                onSubmit={onSubmit}
                                noValidate
                            >
                                <div className={classes.form}>
                                    <div className={classes.input}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            autoFocus
                                            onChange={e => setUsername(e.currentTarget.value)}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            onChange={e => setPassword(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        className={classes.button}
                                        style={{margin: '15px auto'}}
                                    >
                                        {loading && (
                                            <CircularProgress
                                                className={classes.icon}
                                                size={18}
                                                thickness={2}
                                            />
                                        )}
                                        Sign in
                                    </Button>
                                </CardActions>
                                <Link to='/forgotten-password' style={{textDecoration: 'none', display: 'block', textAlign: 'center'}}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        className={classes.button}
                                    >
                                        <Typography component="div" variant="subtitle1" color="inherit" gutterBottom style={{textAlign: 'center'}}>
                                            Forgotten password?
                                        </Typography>
                                    </Button>
                                </Link>
                                <a href={'https://admin.' + FRONTEND_URL + '/register'} style={{textDecoration: 'none', display: 'block', textAlign: 'center'}}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        className={classes.button}
                                    >
                                        Register
                                    </Button>
                                </a>
                            </form>
                        </Card>
                        {/*<Notification />*/}
                    </div>
                :
                    <div className={classnames(classes.main)}>
                        <div className={classnames(classes.main)} style={{backgroundColor: '#0000006b', width: '100%'}}>
                            <Typography variant='h3' style={{color: 'white'}}>
                                Redirecting...
                            </Typography>
                            <CircularProgress style={{color: 'white'}}/>
                        </div>
                    </div>
            }
        </div>
    );
}

Login.propTypes = {
    backgroundImage: PropTypes.string,
    classes: PropTypes.object,
};

Login.defaultProps = {
    backgroundImage: 'https://source.unsplash.com/random/1600x900/daily',
};

export default Login;
