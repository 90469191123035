import {BehaviorSubject} from 'rxjs';
import {fetchIt, getErrorMessage} from "../helpers";
import {userDataSubject, schoolDataSubject} from './user.service';
import {isLoadingService} from './common.service';
import {notificationService} from "./notification.service";


const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () {return currentUserSubject.value}
};


function login(username, password) {
    isLoadingService.setIsLoading(true);

    localStorage.removeItem('user');
    localStorage.removeItem('account');
    localStorage.removeItem('school');

    return fetchIt(
        'post',
        'login',
        {username, password}
    ).then(response => {
        let error = 'Oops, something went wrong!';

        switch (response.status) {
            case 200:
                isLoadingService.setIsLoading(true);

                localStorage.setItem('user', JSON.stringify(response.data));
                currentUserSubject.next(response.data);

                fetchIt(
                    'GET_ONE',
                    'account',
                    {id: 1}
                ).then(accountResponse => {
                    if (accountResponse.status === 200) {
                        isLoadingService.setIsLoading(true);

                        localStorage.setItem('account', JSON.stringify(accountResponse.data));
                        userDataSubject.next(accountResponse.data);

                        fetchIt(
                            'GET_LIST',
                            'schools'
                        ).then(schoolResponse => {
                            if (schoolResponse.status === 200) {
                                localStorage.setItem('school', JSON.stringify(schoolResponse.data[0]));
                                schoolDataSubject.next(schoolResponse.data[0]);

                                return Promise.resolve();
                            }

                            return Promise.reject();
                        }).then(() => {
                            isLoadingService.setIsLoading(false);
                        });

                        return Promise.resolve();
                    }

                    return Promise.reject();
                }).catch(() => {}).then(() => {
                    isLoadingService.setIsLoading(false);
                });

                break;

            case 400:
                authenticationService.logout();

                error = 'The email and/or password are not correct.';
                notificationService.notify({
                    message: getErrorMessage(response.data),
                    type: 'warning',
                });

                return Promise.reject(error);

            default:
                authenticationService.logout();
                window.location.reload();

                return Promise.reject(error);
        }
    }).catch(() => {}).then(() => {
        isLoadingService.setIsLoading(false);
    });
}


function logout() {
    localStorage.removeItem('account');
    userDataSubject.next(null);

    localStorage.removeItem('school');
    schoolDataSubject.next(null);

    currentUserSubject.next(null);

    if (localStorage.getItem('user') !== null) {
        const refreshToken = JSON.parse(window.localStorage.getItem('user')).refresh_token;
        localStorage.removeItem('user');

        return fetchIt(
            'post',
            'logout',
            {token: refreshToken}
        ).then(response => {
            return;
        }).catch(error => {
            return Promise.resolve();
        }).then(() => {
            window.location.reload();
        });
    }

    return Promise.resolve();
}
