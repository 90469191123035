import React from 'react';
import './App.css';
import {BrowserRouter as Router} from "react-router-dom";
import Routes from "./Routes";
import {createBrowserHistory} from 'history';
import {createMuiTheme} from '@material-ui/core/styles';
import {
    Paper, Portal,
} from "@material-ui/core";
import {ThemeProvider} from '@material-ui/styles';
import Notification from "./Notification";


export const history = createBrowserHistory();


const App = () => {
    return (
        <Router history={history}>
            <ThemeProvider theme={theme}>
                <Paper>
                    <Routes />
                </Paper>

                <Portal>
                    <Notification />
                </Portal>
            </ThemeProvider>
        </Router>
  );
}

export default App;

const appBarBackgroundGreen = "#3B9287";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: appBarBackgroundGreen,
        },
        white: {
            main: '#FFFFFF'
        }
    },
    overrides: {
        MuiTypography: {
            h3: {
                fontWeight: 200
            }
        }
    }
});
