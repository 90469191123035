import React from 'react';
import {Typography} from "@material-ui/core";
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import {Card} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        backgroundColor: '#FCFCFE'
    },

    card: {
        minWidth: 360,
        padding: theme.spacing(4),
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: 50,
        boxShadow: 'none',
        border: '1px solid #e0e0e3',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    icon: {
        color: '#6CA454',
        fontSize: 60
    },
}));



const ThankYou = () => {
    const classes = useStyles();
    return (
        <div className={classnames(classes.main)}>
            <Card className={classnames(classes.card)}>
                <div className={classnames(classes.iconContainer)}>
                    <CheckCircleRoundedIcon fontSize='large' className={classnames(classes.icon)} />
                </div>
                <Typography variant='h3' style={{textAlign: 'center', paddingTop: 25}}>
                   Your payment was successful
                </Typography>
                <Typography variant='subtitle1' style={{textAlign: 'center'}} >
                    Thank you! We will be in contact with more details shortly.
                </Typography>
            </Card>           
        </div>
    );
}

export default ThankYou;
