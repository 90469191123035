import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {fetchIt, getErrorMessage} from "../helpers";
import EmailIcon from "@material-ui/icons/Email";
import {isLoadingService, notificationService} from "../services";
import {Button, CircularProgress, TextField} from "@material-ui/core";


const styles = ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

    formPaper: {
        padding: 25
    },

    inputPadding: {
        margin: '25px 0px',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    }
});


const ForgottenPassword = props => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState('');


    useEffect(() => {
        isLoadingService.isLoadingObject.subscribe(data => setIsDisabled(data));
    }, []);


    const {classes} = props;
    const emailRegEx = /^[a-zA-Z0-9.’+_'`-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


    const onSubmit = event => {
        event.persist();
        event.nativeEvent.preventDefault();

        isLoadingService.setIsLoading(true);

        if (email.length === 0) {
            setErrors('The email is required');

            notificationService.notify({
                message: 'The email is required',
                type: 'error'
            });

            isLoadingService.setIsLoading(false);

            return false;
        } else if (!!errors) {
            notificationService.notify({
                message: errors,
                type: 'error'
            });

            isLoadingService.setIsLoading(false);

            return false;
        }


        const data = {
            Email: email
        }

        fetchIt(
            'post',
            'forgotten_password',
            data
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Password Reset Link sent.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    notificationService.notify({message});

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    notificationService.notify({
                        message: errorMessage,
                        type: 'error'
                    });

                    break;

                default:
                    notificationService.notify({
                        message: 'Oops, something went wrong!',
                        type: 'error'
                    });
            }
        }).catch(() => {}).then(() => {
            isLoadingService.setIsLoading(false);
        });

        return false;
    };


    const onChange = event => {
        const newEmail = event.target.value;
        setEmail(newEmail);

        if (newEmail.length === 0) {
            setErrors('The email is required');
        } else if (!newEmail.match(emailRegEx)) {
            setErrors('The email is not valid');
        } else {
            setErrors('');
        }
    }


    return (
        <div className={classes.main}>
            <Grid container>
                <Grid item xs={12} md={4}> </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container>
                        <Grid item xs={12} md={2}> </Grid>
                        <Grid item xs={12} md={9}>
                            <Paper className={classes.formPaper}>
                                <Typography component="h1" variant="h5" align="center">Reset Password</Typography>
                                <form
                                    style={{textAlign: 'center'}}
                                    onSubmit={onSubmit}
                                    noValidate
                                >
                                    <TextField
                                        label='Email *'
                                        type='email'
                                        onChange={onChange}
                                        fullWidth
                                        variant="standard"
                                        className={classes.inputPadding}
                                        helperText={!!errors && errors}
                                        error={!!errors}
                                    />

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={isDisabled}
                                        className={classes.button}
                                        style={{margin: '15px auto'}}
                                    >
                                        {isDisabled && (
                                            <CircularProgress
                                                className={classes.icon}
                                                size={18}
                                                thickness={2}
                                            />
                                        )}&nbsp;
                                        <EmailIcon />&nbsp;Send Password Reset Link
                                    </Button>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}


export default withStyles(styles)(ForgottenPassword);
