import {BehaviorSubject} from 'rxjs';


const isLoadingSubject = new BehaviorSubject(false);

export const isLoadingService = {
    setIsLoading,
    isLoadingObject: isLoadingSubject.asObservable(),
    get isLoading () {return isLoadingSubject.value},
};


function setIsLoading (isLoading) {
    isLoadingSubject.next(isLoading);
}
