import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {fetchIt, getErrorMessage} from "../helpers";
import {Button, CircularProgress, TextField} from "@material-ui/core";
import {authenticationService, isLoadingService, notificationService} from "../services";
import {useHistory} from "react-router-dom";


const styles = ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

    formPaper: {
        padding: 25
    },

    inputPadding: {
        margin: '25px 0px',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    },
});


const TeacherPassword = props => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState('');
    let history = useHistory();


    useEffect(() => {
        authenticationService.logout();
        isLoadingService.isLoadingObject.subscribe(data => setIsDisabled(data));
    }, []);


    const hash = props.match.params.hash;
    const tenant = props.match.params.tenant;


    if (!hash || hash.length === 0 || !tenant || tenant.length === 0) {
        notificationService.notify({
            message: 'No hash provided!',
            type: 'warning',
        });

        history.push('/login');
    }


    const {classes} = props;


    const onSubmit = event => {
        event.persist();
        event.nativeEvent.preventDefault();

        if ((password.length > 0) && (password === repeatPassword)) {
            isLoadingService.setIsLoading(true);

            const data = {
                Hash: hash,
                Password: password
            }

            fetchIt(
                'post',
                'users_activate',
                data,
                {'x-lms-tnt': tenant}
            ).then(response => {
                switch (response.status) {
                    case 200:
                        let message = 'New password set.';
                        if (response.data.Message) {
                            message = response.data.Message;
                        }

                        notificationService.notify({message});

                        authenticationService.login(response.data.Email, password);

                        history.push('/login');

                        break;

                    case 400:
                        let errorMessage = 'The form is NOT valid. Please check for errors.';
                        if (typeof response.data.HasValidationErrors !== 'undefined') {
                            errorMessage = getErrorMessage(response.data);
                        }

                        notificationService.notify({
                            message: errorMessage,
                            type: 'error'
                        });

                        break;

                    default:
                        notificationService.notify({
                            message: 'Oops, something went wrong!',
                            type: 'error'
                        });
                }
            })
            .catch(() => {})
            .then(() => {
                isLoadingService.setIsLoading(false);
            });
        } else {
            notificationService.notify({
                message: 'The form is NOT valid. Please check for errors.',
                type: 'error'
            });
        }
    };


    const onPasswordChange = event => {
        const newPassword = event.target.value;
        setPassword(newPassword);

        if (newPassword.length === 0) {
            setPasswordError('The password field is required');

            if (repeatPassword.length === 0) {
                setRepeatPasswordError('');
            } else {
                setRepeatPasswordError('The two passwords should match');
            }
        } else if (newPassword !== repeatPassword) {
            setPasswordError('The two passwords should match');

            if (repeatPassword.length === 0) {
                setRepeatPasswordError('');
            } else {
                setRepeatPasswordError('The two passwords should match');
            }
        } else {
            setPasswordError('');
            setRepeatPasswordError('');
        }
    }


    const onRepeatPasswordChange = event => {
        const newRepeatPassword = event.target.value;
        setRepeatPassword(newRepeatPassword);

        if (password.length === 0) {
            setPasswordError('The password field is required');

            if (newRepeatPassword.length === 0) {
                setRepeatPasswordError('');
            } else {
                setRepeatPasswordError('The two passwords should match');
            }
        } else if (password !== newRepeatPassword) {
            setPasswordError('The two passwords should match');
            setRepeatPasswordError('The two passwords should match');
        } else {
            setPasswordError('');
            setRepeatPasswordError('');
        }
    }


    return (
        <div className={classes.main}>
            <Grid container>
                <Grid item xs={12} md={4}> </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container>
                        <Grid item xs={12} md={2}> </Grid>
                        <Grid item xs={12} md={9}>
                            <Paper className={classes.formPaper}>
                                <Typography component="h1" variant="h5" align="center">Set new password</Typography>
                                <form
                                    style={{textAlign: 'center'}}
                                    onSubmit={onSubmit}
                                    noValidate
                                >
                                    <TextField
                                        label='Password *'
                                        type='password'
                                        fullWidth
                                        variant='standard'
                                        className={classes.inputPadding}
                                        onChange={onPasswordChange}
                                        helperText={!!passwordError && passwordError}
                                        error={!!passwordError}
                                    />
                                    <TextField
                                        label='Repeat Password *'
                                        source='RepeatPassword'
                                        type='password'
                                        fullWidth
                                        variant='standard'
                                        className={classes.inputPadding}
                                        onChange={onRepeatPasswordChange}
                                        helperText={!!repeatPasswordError && repeatPasswordError}
                                        error={!!repeatPasswordError}
                                    />

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={isDisabled}
                                        className={classes.button}
                                        style={{margin: '15px auto'}}
                                    >
                                        {isDisabled && (
                                            <CircularProgress
                                                className={classes.icon}
                                                size={18}
                                                thickness={2}
                                            />
                                        )}
                                        Save
                                    </Button>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}


export default withStyles(styles)(TeacherPassword);
