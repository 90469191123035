import {BehaviorSubject} from 'rxjs';


const defaultNotification = {
    show: false,
    message: 'message',
    type: 'info',
    autoHideDuration: 4000,
};


const notificationSubject = new BehaviorSubject (defaultNotification);

export const notificationService = {
    notify,
    hide,
    notification: notificationSubject.asObservable(),
    get notificationObject () {return notificationSubject.value}
};


function notify (notification) {
    notificationSubject.next({...notificationService.notificationObject, ...notification, show: true});
}

function hide () {
    notificationSubject.next(defaultNotification);
}
